// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { setAuthToken, getToken, getUserInfo, isAuthenticated, login, logout, refreshToken } from '../services/authService';
import { jwtDecode } from "jwt-decode";
import api from '../services/api';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [user, setUser] = useState({
  username: null,
  role: null,
  organizationId: null,
  isAdmin: false
});

  const loadUser = useCallback((token) => {
    try {
      const decodedToken = jwtDecode(token);
          setUser({
            username: decodedToken.sub,
            role: decodedToken.role,
            organizationId: decodedToken.org_id,
            isAdmin: decodedToken.is_admin
          });
      setIsAuthenticated(true);
      setAuthToken(token);
    } catch (error) {
      console.error("Error decoding token:", error);
      setUser(null);
      setIsAuthenticated(false);
      setAuthToken(null);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      loadUser(token);
    }
  }, [loadUser]);



const login = async (username, password) => {
  try {
    const response = await api.post('/token',
      new URLSearchParams({
        username: username,
        password: password
      }).toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    const { access_token } = response.data;
    localStorage.setItem('token', access_token); // Add this line
    setAuthToken(access_token);
    const decodedToken = jwtDecode(access_token);
    const userInfo = {
      username: decodedToken.sub,
      role: decodedToken.role || 'user',
      userType: decodedToken.user_type // Add this line
    };
    setUser(userInfo);
    setIsAuthenticated(true); // Add this line
    return userInfo;
  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      throw new Error(error.response.data.detail || 'An error occurred during login');
    } else {
      throw new Error('Network error occurred');
    }
  }
};


const ecpLogin = async (token) => {
  try {
    localStorage.setItem('token', token);
    setAuthToken(token);
    const decodedToken = jwtDecode(token);

    // Check if the 'sub' claim is a string that needs to be parsed
    let sub = decodedToken.sub;
    if (typeof sub === 'string') {
      try {
        sub = JSON.parse(sub);
      } catch (parseError) {
        console.warn("Unable to parse 'sub' claim, using as-is:", sub);
      }
    }

    const userInfo = {
      username: typeof sub === 'object' ? sub.sub : sub,
      role: decodedToken.role || 'user',
      userType: decodedToken.user_type || 'regular'
    };

    setUser(userInfo);
    setIsAuthenticated(true);
    return userInfo;
  } catch (error) {
    console.error('ECP Login error:', error);
    throw error;
  }
};

  const logout = () => {
  setUser(null);
  setIsAuthenticated(false); // Add this line
  setAuthToken(null);
  localStorage.removeItem('token');
};
const updateUserRole = (role) => {
  setUser(prevUser => ({...prevUser, role}));
};

  const checkTokenExpiration = useCallback(async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      try {
        const newToken = await refreshToken();
        localStorage.setItem('token', newToken);
        loadUser(newToken);
      } catch (error) {
        console.error('Token refresh failed:', error);
        logout();
      }
    } else {
      // Token is still valid, ensure user is set
      if (!user) {
        loadUser(token);
      }
    }
  } else {
    // No token found, ensure user is logged out
    logout();
  }
}, [loadUser, user]);

  useEffect(() => {
    checkTokenExpiration();
    const intervalId = setInterval(checkTokenExpiration, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [checkTokenExpiration]);

  // In AuthContext.js, update the value object:
const value = {
  user,
  login,
  ecpLogin,
  logout,
  isAuthenticated,
  getToken: () => localStorage.getItem('token'),
  updateUser: setUser,
  checkTokenExpiration
};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}