import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography, Button, IconButton, TextField, Box, Chip, Menu, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Tooltip,
  Alert, Snackbar, Fade
} from '@mui/material';
import {
  Edit as EditIcon, Share as ShareIcon, Chat as ChatIcon, Key as KeyIcon,
  Label as LabelIcon, MoreVert as MoreVertIcon, Download as DownloadIcon
} from '@mui/icons-material';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import axios from 'axios';
import SigningOptionsDialog from './SigningOptionsDialog';
import SignPopup from './SignPopup';
import { QRCodeSVG } from 'qrcode.react';
import { isAuthenticated, refreshToken, logout, getToken } from '../services/authService';
import { saveAs } from 'file-saver';
import { format, parseISO } from 'date-fns';
import DigitalStamp from './DigitalStamp';

function DocumentDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [document, setDocument] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTagDialogOpen, setIsTagDialogOpen] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [signingDialogOpen, setSigningDialogOpen] = useState(false);
  const [signingMethod, setSigningMethod] = useState('');
  const [showQRDialog, setShowQRDialog] = useState(false);
  const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(null);
  const [signPopupOpen, setSignPopupOpen] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [signatureInfo, setSignatureInfo] = useState(null);

  const fetchDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${id}`, {
        headers: { 'Authorization': `Bearer ${getToken()}` }
      });
      setDocumentData(response.data);
      setNewTitle(response.data.title);
      setFileUrl(`${process.env.REACT_APP_API_URL}/documents/${id}/content`);
    } catch (error) {
      console.error('Error fetching document:', error);
      setError('Не вдалося отримати дані документа. Будь ласка, спробуйте ще раз.');
      setDocumentData(null);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const fetchSignatureInfo = useCallback(async () => {
    if (documentData && documentData.is_signed) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${id}/signature-info`, {
          headers: { 'Authorization': `Bearer ${getToken()}` }
        });
        console.log("Signature info received:", response.data);
        setSignatureInfo(response.data);
      } catch (error) {
        console.error('Error fetching signature info:', error);
      }
    }
  }, [id, documentData]);

  useEffect(() => {
    if (documentData && documentData.is_signed) {
      fetchSignatureInfo();
    }
  }, [documentData, fetchSignatureInfo]);

useEffect(() => {
  if (documentData && documentData.is_signed) {
    fetchSignatureInfo();
  }
}, [documentData, fetchSignatureInfo]);

  useEffect(() => {
    const checkAuth = async () => {
      if (!isAuthenticated()) {
        const refreshed = await refreshToken();
        if (!refreshed) {
          logout();
          navigate('/login');
        }
      }
    };

    checkAuth();
    fetchDocument();
  }, [navigate, fetchDocument]);


  const handleDownloadClick = useCallback((event) => {
    setDownloadMenuAnchor(event.currentTarget);
  }, []);

  const handleDownloadClose = useCallback(() => {
    setDownloadMenuAnchor(null);
  }, []);

  const handleDownloadOriginal = useCallback(() => {
    window.open(`${process.env.REACT_APP_API_URL}/documents/${id}/content`, '_blank');
    handleDownloadClose();
  }, [id, handleDownloadClose]);

  const handleDownloadSigned = useCallback(() => {
    if (!isAuthenticated()) {
      refreshToken().then(refreshed => {
        if (!refreshed) {
          logout();
          navigate('/login');
        } else {
          setIsDownloading(true);
        }
      });
    } else {
      setIsDownloading(true);
    }
  }, [isAuthenticated, refreshToken, logout, navigate]);

  useEffect(() => {
    const handleDownload = async () => {
        if (isDownloading && documentData) {
          try {
            const token = getToken();
            const downloadUrl = `${process.env.REACT_APP_API_URL}/documents/${id}/download-signed`;

            const response = await fetch(downloadUrl, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.detail || 'Failed to download signed document');
            }

            const blob = await response.blob();
            const filename = `${documentData.title}_signed.zip`;
            saveAs(blob, filename);

            setSnackbarMessage('Документ успішно завантажено');
          } catch (error) {
            console.error('Error downloading signed document:', error);
            setSnackbarMessage(`Помилка завантаження підписаного документа: ${error.message}`);
          } finally {
            setSnackbarOpen(true);
            setIsDownloading(false);
          }
        }
      };

    handleDownload();
  }, [isDownloading, documentData, id, navigate, logout, getToken]);

  const handleChatClick = useCallback(() => {
    localStorage.setItem('chatDocumentId', id);
    navigate('/chat');
  }, [id, navigate]);

  const handleSignDocument = () => {
    setSigningDialogOpen(true);
  };

  const handleCloseSigningDialog = () => {
    setSigningDialogOpen(false);
  };

  const handleSelectSigningOption = (option) => {
    setSigningMethod(option);
    if (option === 'diia' || option === 'smartid') {
      setShowQRDialog(true);
    } else if (option === 'ecp') {
      setSignPopupOpen(true);
    }
    setSigningDialogOpen(false);
  };

  const handleCloseSignPopup = () => {
    setSignPopupOpen(false);
  };

  const handleDocumentSigned = async (signatureData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/documents/${id}/sign`,
        {
          signature_method: 'ecp',
          signature_data: signatureData,
        },
        {
          headers: {
            'Authorization': `Bearer ${getToken()}`
          }
        }
      );

      const updatedDocument = response.data;
      setDocumentData(prevDoc => ({
        ...prevDoc,
        is_signed: updatedDocument.is_signed,
        status: updatedDocument.status,
        signature_date: updatedDocument.signature_date,
        signature_path: updatedDocument.signature_path
      }));

      // Fetch updated signature info
      fetchSignatureInfo();

      setSnackbarMessage('Документ успішно підписано');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error signing document:', error);
      setSnackbarMessage('Помилка підписання документа');
      setSnackbarOpen(true);
    } finally {
      handleCloseSignPopup();
    }
  };

  const handleEditTitle = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/documents/${id}`, { title: newTitle });
      setDocumentData(prevDoc => ({ ...prevDoc, title: newTitle }));
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating document title:', error);
      setError('Не вдалося оновити назву документа. Будь ласка, спробуйте ще раз.');
    }
  };

  const handleShare = () => {
    setSnackbarMessage('Функціонал спільного доступу ще не реалізований');
    setSnackbarOpen(true);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleArchive = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/documents/${id}/archive`);
      navigate('/');
    } catch (error) {
      console.error('Error archiving document:', error);
      setError('Не вдалося заархівувати документ. Будь ласка, спробуйте ще раз.');
    }
  };

  const handleAddTag = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/documents/${id}/tags`, { tag: newTag });
      fetchDocument();
      setNewTag('');
      setIsTagDialogOpen(false);
    } catch (error) {
      console.error('Error adding tag:', error);
      setError('Не вдалося додати тег. Будь ласка, спробуйте ще раз.');
    }
  };

  const renderFileContent = () => {
    if (!documentData || !fileUrl) {
      console.error('Missing documentData or fileUrl:', { documentData, fileUrl });
      return <Typography color="error">Unable to render document content. Missing data.</Typography>;
    }

    const fileExtension = documentData.file_path.split('.').pop().toLowerCase();
    const officeExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

    const encodedDocUrl = encodeURIComponent(`${process.env.REACT_APP_API_URL}/documents/${id}/content`);
    console.log('Encoded document URL:', encodedDocUrl);

    if (officeExtensions.includes(fileExtension) || fileExtension === 'pdf') {
      let viewerUrl;
      if (fileExtension === 'pdf') {
        viewerUrl = `https://docs.google.com/viewer?url=${encodedDocUrl}&embedded=true`;
      } else {
        viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedDocUrl}`;
      }
      console.log('Viewer URL:', viewerUrl);

      return (
        <Box height="600px" width="100%" position="relative">
          {isDocumentLoading && (
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              bgcolor="rgba(0, 0, 0, 0.5)"
              zIndex="1"
            >
              <CircularProgress />
              <Typography variant="h6" color="white" mt={2}>
                Завантаження документа...
              </Typography>
            </Box>
          )}
          <iframe
            src={viewerUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            title="Перегляд документу"
            onLoad={() => setIsDocumentLoading(false)}
            onError={(e) => {
              console.error('Error loading document in iframe:', e);
              setIsDocumentLoading(false);
              setError('Не вдалося завантажити документ. Будь ласка, спробуйте ще раз.');
            }}
          >
            Це вбудований переглядач документів.
          </iframe>
          {documentData.is_signed && signatureInfo && (
          <DigitalStamp signatureInfo={signatureInfo} />
        )}
        </Box>
      );
    } else if (fileExtension === 'txt') {
      return <TextFileViewer fileUrl={fileUrl} />;
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      return <img src={fileUrl} alt="Документ" style={{ maxWidth: '100%', height: 'auto' }} />;
    } else {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="400px">
          <Typography variant="h6" gutterBottom>
            Попередній перегляд недоступний для цього типу файлів
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            href={fileUrl}
            download
          >
            Завантажити файл
          </Button>
        </Box>
      );
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!documentData) {
    return <Typography>документ не знайдено</Typography>;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button onClick={() => navigate('/')} variant="outlined">
          Назад
        </Button>
        <Typography variant="h6">{documentData.title}</Typography>
        <Box>
          <IconButton onClick={() => setIsEditing(true)}><EditIcon /></IconButton>
          <IconButton onClick={handleShare}><ShareIcon /></IconButton>
          <Tooltip title="Чат з документом">
            <IconButton onClick={handleChatClick}><ChatIcon /></IconButton>
          </Tooltip>
          <IconButton onClick={handleSignDocument} disabled={documentData.is_signed}>
            <KeyIcon />
          </IconButton>
          <IconButton onClick={handleDownloadClick}>
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={handleMenuOpen}><MoreVertIcon /></IconButton>
        </Box>
      </Box>

      {isEditing ? (
        <Box mb={2}>
          <TextField
            fullWidth
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <Button onClick={handleEditTitle}>Зберегти</Button>
          <Button onClick={() => setIsEditing(false)}>Відмінити</Button>
        </Box>
      ) : null}

      <Box mb={2} sx={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden', maxHeight: '80vh', overflowY: 'auto' }}>
        {renderFileContent()}
      </Box>

      <Box mt={2}>
        {documentData.tags && documentData.tags.map((tag) => (
          <Chip key={tag} label={tag} style={{ marginRight: 8 }} />
        ))}
        <Chip
          icon={<LabelIcon />}
          label="Додати тег"
          onClick={() => setIsTagDialogOpen(true)}
          variant="outlined"
        />
      </Box>

      <Menu
        anchorEl={downloadMenuAnchor}
        open={Boolean(downloadMenuAnchor)}
        onClose={handleDownloadClose}
      >
        <MenuItem onClick={handleDownloadOriginal}>Оригінальний документ</MenuItem>
        {documentData && documentData.is_signed && (
          <MenuItem
            onClick={handleDownloadSigned}
            disabled={isDownloading}
          >
            {isDownloading ? 'Завантаження...' : 'Підписаний документ (p7s)'}
          </MenuItem>
        )}
      </Menu>

      <SigningOptionsDialog
  open={signingDialogOpen}
  onClose={handleCloseSigningDialog}
  onSelectOption={handleSelectSigningOption}
/>

<SignPopup
  open={signPopupOpen}
  onClose={handleCloseSignPopup}
  documentId={id}
  onDocumentSigned={handleDocumentSigned}
/>

<Dialog open={showQRDialog} onClose={() => setShowQRDialog(false)}>
  <DialogTitle>
    {signingMethod === 'diia' ? 'Підпишіть з Дія.підпис' : 'Підпишіть за допомогою Smart ID'}
  </DialogTitle>
  <DialogContent>
    <Typography>Відскануйте QR-код своїм додатком, щоб підписати документ:</Typography>
    <Box display="flex" justifyContent="center" mt={2}>
      <QRCodeSVG
        value={`https://signing-url.com/${id}/${signingMethod}`}
        size={200}
      />
    </Box>
  </DialogContent>
</Dialog>

<Dialog open={isTagDialogOpen} onClose={() => setIsTagDialogOpen(false)}>
  <DialogTitle>Add Tag</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Новий тег"
      fullWidth
      value={newTag}
      onChange={(e) => setNewTag(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsTagDialogOpen(false)}>Відміна</Button>
    <Button onClick={handleAddTag}>Додати</Button>
  </DialogActions>
</Dialog>

<Snackbar
  open={snackbarOpen}
  autoHideDuration={6000}
  onClose={() => setSnackbarOpen(false)}
  message={snackbarMessage}
/>
</Box>
);
}





function TextFileViewer({ fileUrl }) {
const [content, setContent] = useState('');
const [error, setError] = useState(null);

useEffect(() => {
  axios.get(fileUrl)
    .then(response => setContent(response.data))
    .catch(error => {
      console.error('Error fetching text file:', error);
      setError('Не вдалося завантажити текстовий файл. Будь ласка, спробуйте ще раз.');
    });
}, [fileUrl]);

if (error) {
  return <Alert severity="error">{error}</Alert>;
}

return (
  <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxHeight: '600px', overflow: 'auto' }}>
    {content}
  </pre>
);
}

export default DocumentDetail;