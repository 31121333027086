// PrivateRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PrivateRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  console.log('PrivateRoute: isAuthenticated:', isAuthenticated);
  console.log('PrivateRoute: user:', user);
  console.log('PrivateRoute: requiredRole:', requiredRole);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiredRole && user?.role !== requiredRole) {
    console.log('User does not have required role');
    return <Navigate to="/documents" replace />;
  }

  return children;
};

export default PrivateRoute;