import React, { useState, useEffect, useCallback } from 'react';
import { EndUser, EndUserConstants } from 'euscp';
import { Dialog, Snackbar, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Select, MenuItem, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useAuth, refreshToken } from "../contexts/AuthContext";
import { format } from 'date-fns';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

function SignPopup({ open, onClose, documentId, onDocumentSigned }) {
  const [euSign, setEuSign] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [keyFile, setKeyFile] = useState(null);
  const [password, setPassword] = useState('');
  const [signStatus, setSignStatus] = useState('');
  const [caList, setCAList] = useState([]);
  const [selectedCA, setSelectedCA] = useState('');
  const [keyFileFormat, setKeyFileFormat] = useState('jks');
  const [keyInfo, setKeyInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isKeyRead, setIsKeyRead] = useState(false);
  const [keyInfoDialogOpen, setKeyInfoDialogOpen] = useState(false);
  const [isSigningInProgress, setIsSigningInProgress] = useState(false);
  const { getToken } = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [retryCount, setRetryCount] = useState(0);
  const [storedKeyInfo, setStoredKeyInfo] = useState(null);

  const logDebugInfo = useCallback((message, data) => {
  console.log(`[SignPopup] ${message}`, JSON.stringify(data, null, 2));
}, []);

  const handleError = useCallback((error) => {
  logDebugInfo("Error:", error);
  let errorMessage = "Виникла помилка при обробці ключа або підписанні документа.";
  if (error.response && error.response.data && error.response.data.detail) {
    errorMessage = error.response.data.detail;
  } else if (error.message) {
    errorMessage += ` ${error.message}`;
  }
  setSignStatus(errorMessage);
  setKeyInfo(null);
  setIsKeyRead(false);
  setSnackbarMessage(errorMessage);
  setSnackbarSeverity('error');
  setSnackbarOpen(true);
}, [logDebugInfo]);

  useEffect(() => {
    const initializeEUSign = async () => {
      try {
        logDebugInfo("Starting EUSign initialization...");
        const euSignInstance = new EndUser(
          '/euscp.worker.ex-1.3.74.js',
          EndUserConstants.EndUserLibraryType.JS
        );

        await euSignInstance.Initialize({
          language: "uk",
          encoding: "utf-8",
          httpProxyServiceURL: "http://127.0.0.1:8080/Server/ProxyHandler",
          directAccess: true,
          CAs: "/data/CAs.json",
          CACertificates: "/data/CACertificates.p7b"
        });

        logDebugInfo("EUSign initialized successfully");
        setEuSign(euSignInstance);
        setIsInitialized(true);

        // Load CA list
      const response = await fetch("/data/CAs.json");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (!Array.isArray(data) || data.length === 0) {
        throw new Error("Invalid CA list format");
      }
      //logDebugInfo("Loaded CA list:", data);
      setCAList([{ issuerCNs: ["Визначити автоматично"] }, ...data]);
      setSelectedCA("Визначити автоматично");
    } catch (error) {
      handleError(error);
    }
  };

  initializeEUSign();
return () => {
    // Cleanup function
    if (euSign) {
      euSign.Finalize();
    }
  };
}, [logDebugInfo, handleError]);


  const readFileAsArrayBuffer = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const formatDate = useCallback((dateString) => {
    if (!dateString) return 'Не доступно';
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Недійсна дата' : format(date, 'dd.MM.yyyy HH:mm:ss');
  }, []);
const getCertificateInfo = useCallback((keyInfo) => {
  if (keyInfo.ownerInfo && keyInfo.issuer) {
    return [{
      type: 'ЕЦП (ДСТУ 4145)',
      issuer: keyInfo.issuer || 'Не доступно',
      serialNumber: keyInfo.serial || 'Не доступно',
      validFrom: formatDate(keyInfo.certBeginTime),
      validTo: formatDate(keyInfo.certEndTime)
    }];
  }
  return [{ type: 'Інформація про сертифікат недоступна' }];
}, [formatDate]);


const readPrivateKey = useCallback(async () => {
      setIsLoading(true);
      setSignStatus('Зчитування ключа...');
      setIsKeyRead(false);

      if (!euSign || !isInitialized || !selectedCA || !keyFile || !password) {
        setSignStatus('Перевірте всі вхідні дані');
        setIsLoading(false);
        return;
      }

      try {
        const keyData = await readFileAsArrayBuffer(keyFile);
        const keyDataUint8 = new Uint8Array(keyData);

        let keyInfo;
        let jksKeys;

        if (keyFileFormat === 'jks') {
          jksKeys = await euSign.GetJKSPrivateKeys(keyDataUint8);
          logDebugInfo("JKS keys:", jksKeys);
          if (jksKeys && jksKeys.length > 0) {
            keyInfo = await euSign.ReadPrivateKeyBinary(jksKeys[0].privateKey, password);
          } else {
            throw new Error("No keys found in JKS file");
          }
        } else {
          keyInfo = await euSign.ReadPrivateKeyBinary(keyDataUint8, password);
        }

        logDebugInfo("Raw key info:", keyInfo);

        const extractValue = (str, key) => {
          const regex = new RegExp(`${key}=([^;]+)`);
          const match = str.match(regex);
          return match ? match[1] : 'Не доступно';
        };

        const formatDate = (dateString) => {
          if (!dateString) return 'Не доступно';
          return new Date(dateString).toLocaleString('uk-UA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          });
        };

        const formattedKeyInfo = {
          name: extractValue(keyInfo.subject, 'CN'),
          organization: extractValue(keyInfo.subject, 'O'),
          taxNumber: extractValue(keyInfo.subject, 'Serial').replace('TINUA-', ''),
          certificates: [{
            type: 'ЕЦП (ДСТУ 4145)',
            issuer: extractValue(keyInfo.issuer, 'CN'),
            serialNumber: keyInfo.serial || 'Не доступно',
            validFrom: jksKeys && jksKeys[0].certificates[0]
              ? formatDate(jksKeys[0].certificates[0].infoEx.certBeginTime)
              : 'Не доступно',
            validTo: jksKeys && jksKeys[0].certificates[0]
              ? formatDate(jksKeys[0].certificates[0].infoEx.certEndTime)
              : 'Не доступно'
          }]
        };

        logDebugInfo("Formatted key info:", formattedKeyInfo);

        setKeyInfo(formattedKeyInfo);
        setStoredKeyInfo(keyInfo);
        setIsKeyRead(true);
        setSignStatus('Ключ успішно зчитано. Перевірте інформацію та підтвердіть для продовження.');
        setKeyInfoDialogOpen(true);

        if (typeof euSign.SetPrivateKey === 'function') {
          await euSign.SetPrivateKey(keyInfo);
        } else {
          logDebugInfo("SetPrivateKey function is not available. Key info stored in state.");
        }
      } catch (error) {
        logDebugInfo("Error reading private key:", error);
        let errorMessage = "Виникла помилка при обробці ключа.";
        if (error.message) {
          errorMessage += ` ${error.message}`;
        }
        setSignStatus(errorMessage);
        setKeyInfo(null);
        setIsKeyRead(false);
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    }, [euSign, isInitialized, selectedCA, keyFile, password, keyFileFormat, logDebugInfo]);


  const readPrivateKeyWithRetry = useCallback(async () => {
      let retryCount = 0;
      while (retryCount < MAX_RETRIES) {
        try {
          await readPrivateKey();
          return;
        } catch (error) {
          retryCount++;
          if (retryCount >= MAX_RETRIES) {
            handleError(error);
            return;
          }
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        }
      }
    }, [readPrivateKey, handleError]);

  const handleSign = useCallback(async () => {
  setIsSigningInProgress(true);
  setSignStatus('Підписання...');
  logDebugInfo("Початок процесу підписання");

  try {
    if (!euSign || !isInitialized) {
      throw new Error("Бібліотека ЕЦП не ініціалізована");
    }

    if (!isKeyRead) {
      throw new Error('Спочатку зчитайте ключ');
    }

    let isPrivateKeyRead;
    if (typeof euSign.IsPrivateKeyReaded === 'function') {
      isPrivateKeyRead = await euSign.IsPrivateKeyReaded();
    } else {
      isPrivateKeyRead = storedKeyInfo !== null;
    }
    logDebugInfo("Is private key read:", isPrivateKeyRead);

    if (!isPrivateKeyRead) {
      throw new Error("Приватний ключ не зчитано");
    }

    // Get the document content from the backend
    const documentResponse = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${documentId}/content`, {
      responseType: 'arraybuffer',
      headers: {
        'Authorization': `Bearer ${getToken()}`
      }
    });
    const documentContent = new Uint8Array(documentResponse.data);
    logDebugInfo("Отримано вміст документа, розмір:", documentContent.length);

    // Sign the data
    let sign;
    try {
      logDebugInfo("Підписання документа...");
      if (typeof euSign.SignData === 'function') {
        sign = await euSign.SignData(documentContent, false, false);
      } else if (typeof euSign.SignDataRaw === 'function') {
        sign = await euSign.SignDataRaw(documentContent, false, false);
      } else {
        throw new Error("Функція підпису недоступна");
      }
      logDebugInfo("Підпис створено, розмір:", sign.length);
    } catch (error) {
      logDebugInfo("Помилка підписання:", error);
      throw new Error(`Не вдалося створити підпис: ${error.message}`);
    }

      if (!sign) {
        throw new Error("Не вдалося створити підпис");
      }

      // Convert Uint8Array to Base64 string
      const signatureBase64 = btoa(String.fromCharCode.apply(null, sign));

    const requestData = {
      documentId: documentId, // Make sure this is included
      signature_method: 'ecp',
      signature_data: {
        signature: signatureBase64,
        keyInfo: {
          name: keyInfo.name,
          taxNumber: keyInfo.taxNumber,
          organization: keyInfo.organization
        }
      },
      timestamp: new Date().toISOString() // Add this field
    };

    logDebugInfo("Request data:", requestData);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/documents/${documentId}/sign`,
      requestData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        }
      }
    );

    logDebugInfo("Відповідь сервера:", response.data);
    setSignStatus('Документ успішно підписано та перевірено');
    setSnackbarMessage('Документ успішно підписано');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);

    if (typeof onDocumentSigned === 'function') {
      onDocumentSigned(response.data);
    }

    handleSignPopupClose();
  } catch (error) {
    handleError(error);
  } finally {
    setIsSigningInProgress(false);
  }
}, [euSign, isInitialized, isKeyRead, documentId, keyInfo, getToken, onDocumentSigned, logDebugInfo, handleError]);

  const handleSignPopupClose = useCallback(() => {
    onClose(false);
    setSignStatus('');
    setKeyFile(null);
    setPassword('');
    setKeyInfo(null);
    setIsKeyRead(false);
    setRetryCount(0);
  }, [onClose]);

  const KeyInfoDialog = useCallback(({ open, onClose, keyInfo }) => (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Перевірте інформацію про зчитаний ключ</DialogTitle>
        <DialogContent>
          <Typography variant="h6">{keyInfo.name}</Typography>
          <Typography variant="body1">Організація</Typography>
          <Typography variant="body2">{keyInfo.organization}</Typography>
          <Typography variant="body1">РНОКПП</Typography>
          <Typography variant="body2">{keyInfo.taxNumber}</Typography>
          <Typography variant="body1">Сертифікати</Typography>
          {keyInfo.certificates.map((cert, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{cert.type}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Сертифікат виданий</Typography>
                <Typography variant="body2">{cert.issuer}</Typography>
                <Typography>Серійний номер</Typography>
                <Typography variant="body2">{cert.serialNumber}</Typography>
                <Typography>Сертифікат чинний</Typography>
                <Typography variant="body2">
                  з {cert.validFrom} до {cert.validTo}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Скасувати
          </Button>
          <Button onClick={onClose} color="primary" variant="contained">
            Підтвердити
          </Button>
        </DialogActions>
      </Dialog>
    ), []);


    return (
    <>
      <Dialog open={open} onClose={handleSignPopupClose} maxWidth="md" fullWidth>
        <DialogTitle>Підписання документа</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSign(); }}>
              <Select
                value={keyFileFormat}
                onChange={(e) => setKeyFileFormat(e.target.value)}
                fullWidth
                margin="normal"
              >
                <MenuItem value="regular">Звичайний ключ</MenuItem>
                <MenuItem value="jks">JKS файл</MenuItem>
              </Select>
              <input
                type="file"
                onChange={(e) => setKeyFile(e.target.files[0])}
                style={{ display: 'none' }}
                id="key-file-input"
              />
              <label htmlFor="key-file-input">
                <Button variant="contained" component="span" fullWidth sx={{ mt: 2, mb: 2 }}>
                  Обрати файл ключа
                </Button>
              </label>
              {keyFile && <Typography sx={{ mb: 2 }}>{keyFile.name}</Typography>}
              <TextField
                fullWidth
                type="password"
                label="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
              <Select
                value={selectedCA}
                onChange={(e) => setSelectedCA(e.target.value)}
                fullWidth
                margin="normal"
              >
                {caList.map((ca, index) => (
                  <MenuItem key={`${ca.issuerCNs[0]}-${index}`} value={ca.issuerCNs[0]}>
                    {ca.issuerCNs[0]}
                  </MenuItem>
                ))}
              </Select>
              <Button
                onClick={readPrivateKeyWithRetry}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                disabled={!keyFile || !password}
              >
                Зчитати ключ
              </Button>
              {signStatus && (
                <Alert severity={isKeyRead ? "success" : "error"} sx={{ mt: 2 }}>
                  {signStatus}
                </Alert>
              )}
            </Box>
          )}
          {keyInfoDialogOpen && keyInfo && (
            <KeyInfoDialog
              open={keyInfoDialogOpen}
              onClose={() => setKeyInfoDialogOpen(false)}
              keyInfo={keyInfo}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSignPopupClose}>Скасувати</Button>
          <Button
            onClick={handleSign}
            disabled={!isKeyRead || isSigningInProgress}
            variant="contained"
            color="primary"
          >
            {isSigningInProgress ? 'Підписання...' : 'Підписати'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SignPopup;