import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { uk } from 'date-fns/locale';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Layout from './components/Layout';
import Login from './components/Login';
import DocumentList from './components/DocumentList';
import UploadPage from './components/UploadPage';
import DocumentDetail from './components/DocumentDetail';
import Chat from './components/Chat';
import AdminPanel from './components/AdminPanel';
import EntrepreneurDashboard from './components/EntrepreneurDashboard';
import PrivateRoute from './components/PrivateRoute';
import { getToken, refreshToken, logout } from './services/authService';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3a8cff',
    },
    secondary: {
      main: '#9c27b0',
    },
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3a8cff',
    },
    secondary: {
      main: '#9c27b0',
    },
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#3a8cff',
    },
    background: {
      default: '#0f0f0f',
      paper: '#0f0f0f',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

function AuthWrapper() {
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
      } else {
        try {
          await refreshToken();
        } catch (error) {
          console.error('Error refreshing token:', error);
          logout();
          navigate('/login');
        }
      }
    };

    if (!isAuthenticated) {
      checkAuth();
    }
  }, [isAuthenticated, navigate, login]);

  return null;
}



function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={uk}>
        <AuthProvider>
          <Router>
            <AuthWrapper />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/documents" replace />} />
                <Route
                  path="/documents"
                  element={
                    <PrivateRoute>
                      <DocumentList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/upload"
                  element={
                    <PrivateRoute>
                      <UploadPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/document/:id"
                  element={
                    <PrivateRoute>
                      <DocumentDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/chat"
                  element={
                    <PrivateRoute>
                      <Chat />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute requiredRole="admin">
                      <AdminPanel />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/entrepreneur-dashboard"
                  element={
                    <PrivateRoute requiredRole="ENTREPRENEUR">
                      <EntrepreneurDashboard />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Routes>
          </Router>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;