// src/services/authService.js
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import api from './api';


const API_URL = process.env.REACT_APP_API_URL;
const TOKEN_KEY = 'auth_token';

export const getUserInfo = () => {
  const token = getToken();
  if (token) {
    try {
      const decoded = jwtDecode(token);
      return {
        username: decoded.sub,
        role: decoded.role,
        // Add any other relevant user info from the token
      };
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }
  return null;
};

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/token`,
      `grant_type=password&username=${username}&password=${password}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    if (response.data.access_token && response.data.refresh_token) {
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
      setAuthToken(response.data.access_token);
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error.response?.data || error.message);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  setAuthToken(null);
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const isAuthenticated = () => {
  const token = getToken();
  return !!token && !isTokenExpired(token);
};

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

export const refreshToken = async () => {
  try {
    const refresh_token = localStorage.getItem('refreshToken');
    if (!refresh_token) {
      throw new Error('No refresh token available');
    }
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/refresh-token`, { refresh_token });
    const newToken = response.data.access_token;
    localStorage.setItem('token', newToken);
    setAuthToken(newToken);
    return true;
  } catch (error) {
    console.error('Error refreshing token:', error);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return false;
  }
};