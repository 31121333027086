import React, { useState, useEffect, useCallback } from 'react';
import { EndUser } from 'euscp';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, CircularProgress, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import { jwtDecode } from "jwt-decode";
import { UserType } from './userTypes';

const ECPAuth = () => {
    const [keyFile, setKeyFile] = useState(null);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [euSign, setEuSign] = useState(null);
    const [keyFileFormat, setKeyFileFormat] = useState('jks');
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const { ecpLogin } = useAuth();

    useEffect(() => {
        const initializeEUSign = async () => {
            try {
                const euSignInstance = new EndUser();
                await euSignInstance.Initialize({
                    language: "uk",
                    encoding: "utf-8",
                    httpProxyServiceURL: "http://127.0.0.1:8080/Server/ProxyHandler",
                    directAccess: true,
                    CAs: "/data/CAs.json",
                    CACertificates: "/data/CACertificates.p7b"
                });
                setEuSign(euSignInstance);
                console.log("EUSign initialized successfully");
            } catch (error) {
                console.error("Failed to initialize EUSign:", error);
                setError('Помилка ініціалізації EUSign');
            }
        };

        initializeEUSign();
    }, []);

    useEffect(() => {
        const checkTokenExpiration = () => {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                if (decodedToken.exp < currentTime) {
                    ecpLogin(token); // This will trigger a token refresh
                }
            }
        };

        checkTokenExpiration();
        const intervalId = setInterval(checkTokenExpiration, 60000); // Check every minute

        return () => clearInterval(intervalId);
    }, [ecpLogin]);

    const readFileAsArrayBuffer = useCallback((file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    }, []);

    const handleFileChange = (e) => {
        setKeyFile(e.target.files[0]);
    };

    const extractTaxNumber = useCallback((privateKeyInfo) => {
        console.log("Private Key Info:", privateKeyInfo);

        if (privateKeyInfo.subjDRFOCode) {
            console.log("Tax Number found:", privateKeyInfo.subjDRFOCode);
            return privateKeyInfo.subjDRFOCode;
        }

        const subjectInfo = privateKeyInfo.subject || '';
        const taxNumberMatch = subjectInfo.match(/(?:РНОКПП|ІПН):\s*(\d+)/);
        if (taxNumberMatch) {
            console.log("Tax Number found in subject:", taxNumberMatch[1]);
            return taxNumberMatch[1];
        }

        console.log("Tax Number not found");
        return null;
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            if (!keyFile) {
                throw new Error('Будь ласка, оберіть файл ключа');
            }

            const keyData = await readFileAsArrayBuffer(keyFile);
            const keyDataUint8 = new Uint8Array(keyData);

            let keyInfo;
            let jksKeys;

            if (keyFileFormat === 'jks') {
                jksKeys = await euSign.GetJKSPrivateKeys(keyDataUint8);
                console.log("JKS keys:", jksKeys);
                if (jksKeys && jksKeys.length > 0) {
                    keyInfo = await euSign.ReadPrivateKeyBinary(jksKeys[0].privateKey, password);
                } else {
                    throw new Error("No keys found in JKS file");
                }
            } else {
                keyInfo = await euSign.ReadPrivateKeyBinary(keyDataUint8, password);
            }

            console.log("Raw key info:", keyInfo);

            const taxNumber = extractTaxNumber(keyInfo);
            if (!taxNumber) {
                throw new Error('Failed to extract tax number from the key');
            }

            const data = "Authentication request";
            const signature = await euSign.SignDataInternal(true, data);

            let userType;
            if (keyInfo.subjOrg === 'ФІЗИЧНА ОСОБА' && !keyInfo.subjEDRPOUCode) {
                userType = UserType.PRIVATE_ENTREPRENEUR;
            } else if (keyInfo.subjEDRPOUCode) {
                userType = UserType.ORGANIZATION;
            } else {
                userType = UserType.REGULAR;
            }
            const ecpResponse = await axios.post(`${process.env.REACT_APP_API_URL}/auth/ecp`, {
            taxNumber,
            signature: btoa(String.fromCharCode.apply(null, signature)),
            userType: 'private_entrepreneur',
            subjOrg: keyInfo.subjOrg,
            subjTitle: keyInfo.subjTitle,
            subjDRFOCode: keyInfo.subjDRFOCode,
            subjEDRPOUCode: keyInfo.subjEDRPOUCode,
            subjFullName: keyInfo.subjFullName,
            subjLocality: keyInfo.subjLocality
        });

        console.log("ECP Authentication successful", ecpResponse.data);

        const { access_token } = ecpResponse.data;

        try {
            const userInfo = await ecpLogin(access_token);

            let redirectPath = '/documents';
            let message = 'Успішна аутентифікація.';

            switch (userInfo.role) {
                case 'ADMIN':
                    message += ' Ви увійшли як адміністратор.';
                    redirectPath = '/admin';
                    break;
                case 'entrepreneur':
                    message += ' Ви увійшли як підприємець.';
                    redirectPath = '/entrepreneur-dashboard';
                    break;
                default:
                    message += ' Ви увійшли в систему.';
                    break;
            }

            setSnackbar({
                open: true,
                message: message,
                severity: 'success'
            });

            navigate(redirectPath);
        } catch (loginError) {
            console.error('Error during ECP login:', loginError);
            setError(`ECP login failed: ${loginError.message}`);
        }

    } catch (error) {
        console.error('Authentication error:', error);
        if (error.response?.status === 400 && error.response.data.detail === "An entrepreneur account already exists") {
            setSnackbar({
                open: true,
                message: 'Аккаунт підприємця вже існує в системі. Будь ласка, зверніться до адміністратора.',
                severity: 'warning'
            });
        } else {
            setError(`Authentication failed: ${error.response?.data?.detail || error.message}`);
        }
    } finally {
        setLoading(false);
    }
};

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <input
                accept=".jks,.dat,.pfx,.key"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" fullWidth>
                    {keyFile ? keyFile.name : 'Обрати файл ключа'}
                </Button>
            </label>
            <Select
                value={keyFileFormat}
                onChange={(e) => setKeyFileFormat(e.target.value)}
                fullWidth
                margin="normal"
            >
                <MenuItem value="jks">JKS файл</MenuItem>
                <MenuItem value="dat">DAT файл</MenuItem>
            </Select>
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль ключа"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!keyFile || !password || loading}
            >
                {loading ? <CircularProgress size={24} /> : 'Аутентифікація з ЕЦП'}
            </Button>
            {error && (
                <Typography color="error" align="center">
                    {error}
                </Typography>
            )}
            {userInfo && (
                <Box mt={2}>
                    <Typography variant="h6">Login Successful</Typography>
                    <Typography>Welcome, {userInfo.username}</Typography>
                    <Typography>Role: {userInfo.role}</Typography>
                </Box>
            )}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ECPAuth;