import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress, Paper, Snackbar, Alert, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the import path as needed
import ECPAuth from './ECPAuth';

const API_URL = process.env.REACT_APP_API_URL;

// Створюємо стилізований компонент для фону
const BackgroundBox = styled(Box)({
  backgroundImage: 'url("./back.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// Стилізуємо форму входу
const LoginForm = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '15px',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

function Login() {
  const [username, setUsername] = useState('user1');
  const [password, setPassword] = useState('password123');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const navigate = useNavigate();
  const { login, isLoading: authLoading } = useAuth();
  const [authMethod, setAuthMethod] = useState('password');

const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(username, password);
      setSnackbar({ open: true, message: 'Login successful', severity: 'success' });
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      let errorMessage = 'An error occurred during login. Please try again.';
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = error.response.data.detail;
      } else if (error.message) {
        errorMessage = error.message;
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAuthMethodChange = (event, newValue) => {
    setAuthMethod(newValue);
  };


  return (
    <BackgroundBox>
      <LoginForm elevation={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Увійдіть в систему
        </Typography>
        <Tabs value={authMethod} onChange={handleAuthMethodChange} aria-label="auth method tabs">
          <Tab label="Логін/Пароль" value="password" />
          <Tab label="ЕЦП" value="ecp" />
        </Tabs>
        {authMethod === 'password' ? (
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1, width: '100%', maxWidth: 400 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Користувач"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={authLoading}
            >
              {authLoading ? <CircularProgress size={24} /> : 'Вхід'}
            </Button>
          </Box>
        ) : (
          <ECPAuth />
        )}
      </LoginForm>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </BackgroundBox>
  );
}

export default Login;