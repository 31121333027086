import React, { useRef } from 'react';
import { BarChart, Bar, LineChart, Line, ScatterChart, Scatter, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { toPng } from 'html-to-image';

const Chart = ({ data, chartType, xAxis, yAxis, availableColumns, onChartTypeChange, onXAxisChange, onYAxisChange }) => {
  const chartRef = useRef(null);

  const copyChartToClipboard = () => {
    if (chartRef.current) {
      toPng(chartRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'chart.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error('Помилка при копіюванні діаграми:', err);
        });
    }
  };

  const renderChart = () => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxis} fill="#8884d8" />
          </BarChart>
        );
      case 'line':
        return (
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={yAxis} stroke="#8884d8" />
          </LineChart>
        );
      case 'scatter':
        return (
          <ScatterChart>
            <CartesianGrid />
            <XAxis dataKey={xAxis} type="number" />
            <YAxis dataKey={yAxis} type="number" />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name="Data" data={data} fill="#8884d8" />
          </ScatterChart>
        );
      case 'pie':
        return (
          <PieChart>
            <Pie dataKey={yAxis} nameKey={xAxis} data={data} fill="#8884d8" label />
            <Tooltip />
            <Legend />
          </PieChart>
        );
      default:
        return <div>Непідтримуваний тип діаграми</div>;
    }
  };

  return (
    <div ref={chartRef}>
      <FormControl fullWidth margin="normal">
        <InputLabel>Тип діаграми</InputLabel>
        <Select value={chartType} onChange={(e) => onChartTypeChange(e.target.value)}>
          <MenuItem value="bar">Гістограма</MenuItem>
          <MenuItem value="line">Лінійна</MenuItem>
          <MenuItem value="scatter">Розсіювання</MenuItem>
          <MenuItem value="pie">Кільцева</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>X Axis</InputLabel>
        <Select value={xAxis} onChange={(e) => onXAxisChange(e.target.value)}>
          {availableColumns.map((column) => (
            <MenuItem key={column} value={column}>{column}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Y Axis</InputLabel>
        <Select value={yAxis} onChange={(e) => onYAxisChange(e.target.value)}>
          {availableColumns.map((column) => (
            <MenuItem key={column} value={column}>{column}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <ResponsiveContainer width="100%" height={400}>
        {renderChart()}
      </ResponsiveContainer>
      <Button onClick={copyChartToClipboard}>Скопіювати діаграму</Button>
    </div>
  );
};

export default Chart;